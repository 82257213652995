/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2022-12-22",
    versionChannel: "nightly",
    buildDate: "2022-12-22T00:14:42.760Z",
    commitHash: "7a09dd563980b378eb2b6702a227c7b346b5ae3e",
};
